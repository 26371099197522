import axios from "axios";
import moment from "moment";
import transwords from "./transwords_n2.json";
// import transwords from "js/transwords_n.json";

// return the user data from the session storage
export const getUser = () => {
	const userStr = localStorage.getItem("user");
	if (userStr) return JSON.parse(userStr);
	else return null;
};
export const getuserid = () => {
	const useridStr = localStorage.getItem("user_id");
	if (useridStr) return JSON.parse(useridStr);
	else return null;
};
export const getparentuserid = () => {
	const parentuseridStr = localStorage.getItem("parentuserid");
	if (parentuseridStr) return JSON.parse(parentuseridStr);
	else return null;
};
export const getusertype = () => {
	const usertypeStr = localStorage.getItem("usertype");
	if (usertypeStr) return JSON.parse(usertypeStr);
	else return null;
};

export const getclientID = () => {
	const clientIDStr = localStorage.getItem("client_id");
	if (clientIDStr) return JSON.parse(clientIDStr);
	else return null;
};

export const getclientName = () => {
	const clientNameStr = localStorage.getItem("client_name");
	if (clientNameStr) return JSON.parse(clientNameStr);
	else return null;
};

// return the token from the session storage
export const getToken = () => {
	const tokenstr = localStorage.getItem("session_id");
	if (tokenstr) return JSON.parse(tokenstr);
	else return null;
};

// remove the token and user from the session storage
export const removeUserSession = (pathname) => {
	localStorage.clear();
	localStorage.setItem("Last_render", JSON.stringify(pathname));

	localStorage.removeItem("user");
	localStorage.removeItem("user_id");
	localStorage.removeItem("parentuserid");
	localStorage.removeItem("usertype");
	localStorage.removeItem("usercategory");
	localStorage.removeItem("Start_date");
	localStorage.removeItem("End_date");
	localStorage.removeItem("client_id");
	localStorage.removeItem("client_name");
	localStorage.removeItem("session_id");
	localStorage.removeItem("intime");
	localStorage.removeItem("artpg");
	localStorage.removeItem("allowDwn");
	localStorage.removeItem("acmen");

	// window.location.reload();
	window.location.href = "/login";
};

// set the token and user from the session storage
export const setUserSession = (
	user,
	user_id,
	parentuserid,
	usertype,
	usercategory,
	client_id,
	client_name = ""
) => {
	localStorage.setItem("user", JSON.stringify(user));
	localStorage.setItem("user_id", JSON.stringify(user_id));
	localStorage.setItem("parentuserid", JSON.stringify(parentuserid));
	localStorage.setItem("usertype", JSON.stringify(usertype));
	localStorage.setItem("usercategory", JSON.stringify(usercategory));
	localStorage.setItem("client_id", JSON.stringify(client_id));
	localStorage.setItem("client_name", JSON.stringify(client_name));
};

export const updateUserSession = () => {
	// console.log(
	// "token=>" + getToken(),
	// "clientid=>" + getclientID(),
	// "userid=>" + getuserid(),
	// "page_visit=>" + window.location.pathname
	// );

	if (window.location.pathname !== "/loading") {
		localStorage.setItem("intime", moment().format());
		localStorage.setItem("artpg", window.location.pathname);
		// console.log("session log=>" + window.location.pathname);
		// return false;

		axios({
			method: "post",
			// headers: { "Content-Type": "application/json" },
			url: `${process.env.REACT_APP_PUBLIC_URL}/commonmid/loginSes`,
			data: {
				userid: getuserid(),
				clientid: getclientID(),
				session_id: getToken(),
				pagename: window.location.pathname,
				action: "update",
			},
		}).then((res) => {
			// console.log(res.data);
			if (res.data === "session_cleared") {
				removeUserSession(window.location.pathname);
				// window.location.href = "/login";
			}
		});
	}
};

export const getTrans = (lang = "English") => {
	// console.log(lang);
	let langObj = transwords.filter((l) => {
		return l.lang === lang;
	});
	return langObj[0];
};
