// import React from "react";
import "./loader1.scss";

function Loader() {
	return (
		<div className="loader1">
			<div className="loader1__bar"></div>
			<div className="loader1__bar"></div>
			<div className="loader1__bar"></div>
			<div className="loader1__bar"></div>
			<div className="loader1__bar"></div>
			<div className="loader1__ball"></div>
		</div>
	);
}
export default Loader;
