import React, { useState, useEffect } from "react";
import {
	BrowserRouter,
	HashRouter,
	Route,
	Switch,
	useHistory,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import "./scss/style.scss";
// import './App.css'
//Routes Types
import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import { withCookies, Cookies } from "react-cookie";
import CacheBuster from "react-cache-buster";
import Loader from "./components/css/loader1";
import {
	getparentuserid,
	getUser,
	getuserid,
	getusertype,
	removeUserSession,
	getToken,
	getTrans,
} from "./Utils/Common";
import {
	SessionContext,
	MenuContext,
	MenuProvider,
	CopylinkContext_Provider,
} from "./AppContext";
import FusionCharts from "FusionChartXT/js/fusioncharts";
// import FusionCharts from "fusioncharts";
FusionCharts.options.license({
	key: "rpI3xgkA3D5B3E5H5A3H4C10A6C4D2C2E1hH-8sC11C2dbhvcF3H3znE-13F4E2D3E1D4A3B11B1B6E5B1F4I3D1J2B3nqsD1B1G4cA32B13B9vulG4H3D3jnA-21A5A1E3B-9fbA1A7A4A6A2B4C2D3H1F2C2emoE6E1f1H4akzA5E6F4kzH3MC2D5vpaB2E3C1I3B8A6A5B3C3B3E3B2A1B11y==",
	creditLabel: false,
});
var pjson = require("./../package.json");

// import Kupdate from "./views/dashboard/Kupdate";

// import Details from "./views/dashboard/Details";
// import Mediastat_preview from "./views/dashboard/Mediastat_preview";
const Mediastat_preview = React.lazy(() =>
	import("./views/dashboard/Mediastat_preview")
);
const Details = React.lazy(() => import("./views/dashboard/Details"));
// import Details_p from "./views/dashboard/Details_p";
const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./components/Login"));

const SessionProvider = ({ children }) => {
	let lang = localStorage.getItem("sesLang")
		? JSON.parse(localStorage.getItem("sesLang"))
		: "English";
	const [transwords, setTranswords] = useState(getTrans(lang));
	const [sessionLang, setSessionLang] = useState({
		// userId: null,
		// isAuthenticated: false,
		// language: "Englishd",
		language: lang,
	});

	const updateSessionLang = (newSession) => {
		setSessionLang((prevSession) => ({ ...prevSession, ...newSession }));
		localStorage.setItem("sesLang", JSON.stringify(newSession.language));

		setTranswords(getTrans(newSession.language));
	};

	// useEffect(() => {
	// 	setTranswords(getTrans(lang));
	// }, [lang]);

	// console.log(lang, transwords);

	return (
		<SessionContext.Provider
			value={{ ...sessionLang, updateSessionLang, transwords }}
		>
			{children}
		</SessionContext.Provider>
	);
};

function App(props) {
	const history = useHistory();
	const inTime = localStorage.getItem("intime");
	const twoTime = moment(inTime).add(2, "h");

	window.onbeforeunload = function (e) {
		window.onunload = function () {
			localStorage.setItem("intime", moment().format());
			// console.log("onunload " + moment(inTime).isAfter(twoTime));
		};
		return undefined;
	};

	window.onload = function () {
		// console.log("onload " + moment().isAfter(twoTime));
		if (moment().isAfter(twoTime) === true) {
			removeUserSession(history.location.pathname);
			// history.push("/login");
		}
	};

	useEffect(() => {
		const userid = getuserid();
		const session_id = getToken();

		if (!userid && !session_id) {
			return <Login />;
		}
	}, []);
	const isProduction = process.env.NODE_ENV === "production";
	// console.log(pjson.version);
	return (
		<>
			<CacheBuster
				currentVersion={pjson.version}
				isEnabled={isProduction} //If false, the library is disabled.
				isVerboseMode={false} //If true, the library writes verbose logs to console.
				loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
				metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
			>
				<BrowserRouter>
					<SessionProvider>
						<CopylinkContext_Provider>
							<React.Suspense fallback={loading}>
								<Switch>
									<Route
										exact
										path="/"
										name="Login Page"
										render={(props) => <Login {...props} />}
									/>
									<Route path="/login" component={Login} />
									<Route path="/details" component={Details} />
									<Route
										path="/media_preview"
										component={Mediastat_preview}
									/>
									{/* <Route path="/details_p" component={Details_p} /> */}
									<MenuProvider>
										<Route
											path="/"
											name="Home"
											render={(props) => (
												<PrivateRoute
													component={DefaultLayout}
													{...props}
												/>
											)}
										/>
									</MenuProvider>
								</Switch>
							</React.Suspense>
						</CopylinkContext_Provider>
					</SessionProvider>
				</BrowserRouter>
				{/* <div
				className="copyright"
				style={{
					textAlign: "center",
					fontSize: "12px",
					background: "transparent",
				}}
			>
				{new Date().getFullYear() + " "} &copy; KANALYTICS - Kwicky Client
				Dashboard. | <a style={{ color: "#000" }}>Privacy Policy</a>
			</div> */}
			</CacheBuster>
		</>
	);
}

export default App;
