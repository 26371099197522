import { createStore } from "redux";

const initialState = [
	{
		sidebarUnfoldable: true,
	},
	{
		sidebarShow: true,
	},
	{
		anagroupShow: false,
	},
];
// console.log(initialState);
const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case "set":
			return { ...state, ...rest };
		default:
			if (window.innerWidth <= 760) {
				return state[1];
			} else {
				return state[0];
			}
	}
};

const store = createStore(changeState);
export default store;
