import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getuserid } from "./Common";

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				!getuserid() ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: "/" }} />
				)
			}
		/>
	);
}

export default PublicRoute;
