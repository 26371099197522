import React, { useState, createContext } from "react";
import { getToken, getuserid } from "./Utils/Common";
import axios from "axios";
import moment from "moment";
var CryptoJS = require("crypto-js");

const SessionContext = createContext({
	// userId: null,
	// isAuthenticated: false,
	language: "English",
});

const MenuContext = createContext();
const MenuProvider = ({ children }) => {
	const decryptedData =
		getToken() &&
		localStorage.getItem("acmen") &&
		JSON.parse(
			CryptoJS.AES.decrypt(
				localStorage.getItem("acmen"),
				getToken()
			).toString(CryptoJS.enc.Utf8)
		);
	// console.log(decryptedData);

	const [menu, setMenu] = useState(decryptedData);

	const updateMenu = (newMenu) => {
		setMenu((prevMenu) => ({ ...prevMenu, ...newMenu }));
	};
	// console.log(decryptedData);
	return (
		<MenuContext.Provider value={{ menu, updateMenu }}>
			{children}
		</MenuContext.Provider>
	);
};

const ExportContext = createContext();
const ExportContext_Provider = ({ children }) => {
	const Startdate = JSON.parse(localStorage.getItem("Start_date"));
	const Enddate = JSON.parse(localStorage.getItem("End_date"));

	const exportFunction = (outputData, fname, date_range) => {
		// console.log(outputData);
		const headers =
			Object.keys(outputData[0])
				.map((header) => header.charAt(0).toUpperCase() + header.slice(1))
				.join(",") + "\n";
		const rows = outputData.map((item) =>
			Object.values(item)
				.map((value) => `"${value}"`)
				.join(",")
		);
		const csvData = headers + rows.join("\n");
		const blob = new Blob(["\uFEFF" + csvData], {
			type: "text/csv;charset=utf-8;",
		});

		const link = document.createElement("a");
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			let filename_date = date_range
				? ` - ${moment(Startdate).format("DD-MMM")}-${moment(
						Enddate
				  ).format("DD-MMM")}`
				: ``;

			let filename = `SM Audit - ${fname}${filename_date}`;
			link.setAttribute("download", filename);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			// setExportcsv(iniExportcsv);
		}
	};

	return (
		<ExportContext.Provider value={{ exportFunction }}>
			{children}
		</ExportContext.Provider>
	);
};

const CopylinkContext = createContext();
const CopylinkContext_Provider = ({ children }) => {
	const iniIsCopied = { copied: false, copiedload: false, error: false };
	const [isCopied, setIsCopied] = useState({});

	const copyShortUrl = (url, idkey) => {
		// console.log(url);
		setIsCopied((prevIsCopied) => ({
			...prevIsCopied,
			[idkey]: { ...iniIsCopied, copiedload: true },
		}));

		/* setTimeout(async () => {
			await copy(url, idkey);
		}, 3000); */

		axios({
			method: "post",
			headers: { "Content-Type": "application/json" },
			url: `${process.env.REACT_APP_PUBLIC_URL}/commonmid/shorturl`,
			data: {
				userid: getuserid(),
				url: url,
			},
		})
			.then(async (res) => {
				await copy(res.data.result[0].short_url, idkey);
			})
			.catch(async (err) => {
				// console.log(err);
				await dispStatus(false, idkey);
			});
	};

	const copy = async (value, idkey) => {
		const tempInput = document.createElement("input");
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		await dispStatus(true, idkey, value);
	};

	const dispStatus = (status, idkey, surl = "") => {
		let copyobj = { copied: true, url: surl };
		setIsCopied((prevIsCopied) => ({
			...prevIsCopied,
			[idkey]: {
				...iniIsCopied,
				...(status ? { ...copyobj, showcopy: true } : { error: true }),
			},
		}));

		setTimeout(() => {
			setIsCopied((prevIsCopied) => ({
				...prevIsCopied,
				[idkey]: status ? { ...copyobj, showcopy: false } : iniIsCopied,
			}));
		}, 3000);
	};

	// console.log(isCopied);

	return (
		<CopylinkContext.Provider
			value={{ iniIsCopied, isCopied, setIsCopied, copyShortUrl, copy }}
		>
			{children}
		</CopylinkContext.Provider>
	);
};

export {
	SessionContext,
	MenuContext,
	MenuProvider,
	ExportContext,
	ExportContext_Provider,
	CopylinkContext,
	CopylinkContext_Provider,
};
